import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';
import homedata from '../../data/homedata.json';

class Header extends Component {

   render() {
      let imgattr = 'image'
      let publicUrl = process.env.PUBLIC_URL
      return (
      <div>
      <header id="home">
         <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header">
            <div className="container">
               <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                  <img src={process.env.PUBLIC_URL + '/assets/img/logo.png' } alt={imgattr}/>
               </a>
               <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
               <i className="icofont-navigation-menu"></i>
               </button>
               <div className="collapse navbar-collapse" id="navbarCodeply">
                  <ul className="nav navbar-nav ml-auto">
                  <li><HLink className="nav-link" smooth to={`/#home`}>בית</HLink></li>
                  <li><HLink className="nav-link" smooth to={`/#feature`}>אודות</HLink></li>
                  <li><HLink className="nav-link" smooth to={`/#game`}>נסו בעצמכם</HLink></li>
                  {/* <li><Link className="nav-link" smooth  to="/blogs">בלוג</Link></li> */}
                  <li><HLink className="nav-link" smooth to={`/#contact`}>צור קשר</HLink></li>
                  </ul>
               </div>
            </div>
         </nav>
      </header>      
      <li><a target="_blank" className="sticky-phone js-sticky-phone" href="https://wa.me/972584729698?text=היי, אפשר לשמוע עוד פרטים על Wiply">
      <img className='whatsappicon' src="https://res.cloudinary.com/shulgirit/image/upload/v1720767813/wiply/whatsapp-512_sz9szq.webp" alt="whatsapp" /></a></li>
      </div>

    );
   }
}

export default Header;
