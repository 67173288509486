import React, { useState } from "react";

import "./Terms.css";

function Terms() {
  return (
    <div style={{ direction: "rtl", textAlign: "right" }}>
      <div className="terms">
        <h1>תנאי שימוש</h1>
        <p>
          ברוכים הבאים לWiply.co.il- (להלן: “האתר”). האתר מופעל ע”י וויפלי מ.א
          מסחר אלקטרוני בע"מ (להלן: “החברה”). האתר משמש כאתר סחר אלקטרוני אשר
          מציע, (בין היתר) למכירה חבילות ושירותים שונים (להלן: “המוצרים”).
        </p>
        <ol type="1">
          <li>
            <div>
              <b>כללי </b>
              <div></div>
            </div>
            <ol type="1">
              <li>
                הוראות תקנון זה ותנאי השימוש המופיעים באתר מגדירים את מערכת
                היחסים המשפטית בין המזמין לבין האתר, את תנאי השימוש באתר ו/או את
                הזמנת המוצרים מן האתר ומעידים על הסכמת המזמין, לתנאים אלה
                ולתנאים נוספים המופיעים באתר.
              </li>
              <li>
                החברה רשאית בכל עת, על פי שיקול דעתה הבלעדי, לעדכן תקנון זה.
              </li>
              <li>
                תנאים אלה חלים על השימוש באתר ובשירותים הכלולים בו באמצעות כל
                מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, טבלטים למיניהם
                וכו’) כמו כן הם חלים על השימוש באתר, בין באמצעות רשת האינטרנט
                ובין באמצעות כל רשת או אמצעי תקשורת אחר.
              </li>
              <li>
                אין באמור בתקנון זה כדי לגרוע מהוראות חוק הגנת הצרכן,
                התשמ”א-1981 (להלן: “חוק הגנת הצרכן”) והתקנות אשר הותקנו מכוחו,
                ככל שהן חלות על האתר (להלן: “ההוראות”), למעט במקרים בהם ניתן
                להתנות על הוראות כאמור והתניה כאמור בוצעה במסגרת האתר אם במפורש
                ואם במשתמע.
              </li>
              <li>
                החברה והנהלת האתר עושים כמיטב יכולתם להציג את המידע השלם והמקיף
                ביותר לגבי המוצר לרבות תמונות, חרף האמור בסעיף זה יובהר, כי
                עלולים להופיע באתר בתום לב וללא כוונת זדון ו/או מתוך רצון
                להטעות, אי דיוקים ו/או שגיאות ו/או השמטות והחברה, הנהלת האתר
                והאתר לא יישאו באחריות כלשהי הנובעת מאי הדיוקים ו/או או השגיאות.
              </li>
              <li>
                כותרות הפרקים מובאות לשם נוחות והתמצאות המזמין ולא ישמשו בפרשנות
                התקנון.
              </li>
              <li>טעות סופר בתיאור מוצר/ים לא תחייב את החברה.</li>
              <li>
                תמונות המוצרים באתר מוצגות לצורכי המחשה בלבד. בנוסף, ייתכנו
                הבדלים במראה, בגוון, בגודל, וכיו”ב בין המוצר, כפי שהוא מוצג
                באתר, לבין המוצר במציאות.
              </li>
              <li>
                אין להעתיק ולהשתמש, או לאפשר לאחרים להשתמש, בכל דרך אחרת בתכנים
                מתוך האתר, לרבות באתרי אינטרנט אחרים, בפרסומים אלקטרוניים,
                בפרסומי דפוס וכיו”ב, לכל מטרה אחרת.
              </li>
              <li>
                המועד שנרשם, לכל עניין ועניין, במחשבי החברה הוא המועד הקובע לכל
                דבר.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>הרשמה לאתר</b>
              <div></div>

              <div>
                על מנת לבצע הזמנה של מוצרים על המזמין להירשם באתר באמצעות טופס
                הרשמה מקוון.
              </div>
            </div>
            <ol type="1">
              <li>
                כל אדם, לרבות חברה, רשאי להשתמש באתר, לרבות לבצע רכישות באמצעות
                האתר, בין היתר, בכפוף להיותו כשיר לבצע פעולות משפטיות מחייבות, ,
                בעל כרטיס אשראי תקף ,שהונפק ע”י אחת מחברות כרטיסי האשראי (לעיל
                ולהלן: “המזמין”).
              </li>
              <li>
                הרישום הינו חד פעמי, כאשר לאחריו המזמין לא יידרש לשוב ולהירשם
                בכל פעם מחדש בעת ביצוע הרכישה.
              </li>
              <li>
                בעת הרישום באתר על המזמין להזין שם משתמש וסיסמא וכן את כתובת
                הדואר האלקטרוני שלו. במידה וישכח המזמין את הסיסמא, יוכל לבצע
                איפוס סיסמה ולייצר סיסמה חדשה.
              </li>
              <li>
                בעתיד אם וכאשר יבקש המזמין לרכוש מוצרים נוספים, יזוהה המזמין לפי
                שם המזמין והסיסמא שבחר.
              </li>
              <li>
                פרטי המזמין יעודכנו בהתאם לנתונים אשר ימולאו על ידי המזמין בטופס
                הרישום המקוון במצוי באתר.
              </li>
              <li>הנהלת האתר רשאית מעת לעת לדרוש פרטי זיהוי נוספים.</li>
              <li>
                מבלי לגרוע מהאמור לעיל, החברה תהא רשאית למנוע מכל אדם להשתמש
                באתר, באופן זמני או לצמיתות על פי שיקול דעתה הבלעדי ומבלי למסור
                הודעה על כך מראש, לרבות בכל אחד מהמקרים הבאים:
                <ol type="1">
                  <li>ביצוע מעשה בלתי חוקי ו/או הפרת הוראות הדין</li>
                  <li> הפרת תנאי מתנאי תקנון זה</li>
                  <li>מסירת פרטים שגויים במתכוון</li>
                  <li>
                    ביצוע פעולה העלולה לפגוע בפעילותו התקינה של האתר ו/או במי
                    מהספקים ו/או בצד ג’ כלשהו;
                  </li>
                  <li>
                    כרטיס האשראי שברשות המזמין נחסם או הוגבל לשימוש בדרך כלשהי.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b> רכישת מוצרים באתר</b>
            </div>
            <ol type="1">
              <li>
                מילוי כל הפרטים הנדרשים לרכישת המוצר על ידי המזמין יחשב לביצוע
                הזמנה (להלן:”ההזמנה”) עם קבלת ההזמנה, האתר יבצע בדיקה של פרטי
                כרטיס האשראי ורק לאחר אישור חברת האשראי ו/או לאחר קבלת אישור
                מפיי פאל כפי שיובהר להלן, הפעולה תאושר ויצא אישור סופי של
                ההזמנה, חיוב הלקוח עבור המוצר יבוצע דרך כרטיס האשראי ו/או דרך
                חשבון הפיי פאל כפי שיבואר להלן.
              </li>
              <li>
                הפרטים כפי שהוזנו בטופס ההזמנה על ידי המזמין, יהוו ראיה חלוטה
                לנכונות הפעולות.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b> אופן ביצוע התשלום עבור ההזמנה</b>
            </div>
            <ol type="1">
              <li>
                התשלום עבור המוצרים יעשה באמצעות כרטיס אשראי או באמצעות חשבון
                בשירות “ארנק אלקטרוני” כדוגמת פיי פאל- (WWW.PAYPAL.COM) וכל
                שירות דומה , כפי שיהיה זמין לשימוש באתר, מעת לעת לפי שיקול דעתה
                של הנהלת האתר.
              </li>
              <li>
                אם יבחר המזמין להשתמש בכרטיס אשראי לשם ביצוע התשלום, יתבקש
                המזמין למסור את פרטי כרטיס האשראי, תעודת זהות, סוג הכרטיס
                ותוקפו. אם החליט המזמין לשלם באמצעות הפיי פאל, החברה תוכל לגבות
                את התשלום עבור המוצרים רק לאחר קבלת אישור מפיי פאל. השימוש וקבלת
                האישור מפיי-פאל כפופים לתנאי השימוש של אתר פיי פאל.
              </li>
              <li>
                הנהלת האתר שומרת לעצמה את הזכות להפסיק את השימוש באמצעי התשלום
                כלשהו באתר, להתיר שימוש באמצעי תשלום נוספים ולהחיל הסדרי תשלום
                שונים על סוגי כרטיסי אשראי שונים או אמצעי תשלום שהנהלת האתר
                תכבד.
              </li>
              <li>
                לאחר שהוזנו פרטי התשלום בטופס ההזמנה המקוון, ישלח אל המזמין
                אישור באמצעות הדואר האלקטרוני על קליטת פרטי ההזמנה. יובהר כי
                אישור זה אינו מחייב את הנהלת האתר לספק את המוצרים שהוזמנו והוא
                רק מעיד שפרטי ההזמנה נקלטו בהנהלת האתר.
              </li>
              <li>
                מיד לאחר ביצוע ההזמנה, תבצע הנהלת האתר בדיקה של אמצעי התשלום בו
                השתמש המזמין, תינתן הודעה מתאימה למזמין כי ההזמנה אושרה וחשבונו
                יחויב בעלות השירות.
              </li>
              <li>
                במקרה בו לא אושרה העסקה על ידי חברת האשראי או על ידי חשבון הפיי
                פאל או כל אמצעי תשלום אר שיהיה באותה עת, יקבל המזמין הודעה
                מתאימה על כך והנהלת האתר תיצור קשר עם המזמין לשם השלמת העסקה או
                ביטולה.
              </li>
              <li>
                במידה והמזמין חויב בטעות על ידי חברת האשראי, על המזמין להודיע
                להנהלת האתר על מנת לבצע זיכוי בהתאם.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>ביטול רכישה מצד המזמין</b>
            </div>
            <ol type="1">
              <li>
                המזמין רשאי לבטל את העסקה בהתאם להוראות חוק הגנת הצרכן והתקנות
                שהותקנו על פיו. מבלי לגרוע מהאמור:
                <ol type="1">
                  <li>
                    ביטול ייעשה אך ורק בהודעה בכתב לכתובת הדוא”ל -
                    info@wiply.com
                  </li>
                  <li>
                    ביטול עסקה ע”י המזמין לא יתאפשר ברכישת פריטים מסוימים כמפורט
                    בסעיף קטן 14ג(ד) לחוק הגנת הצרכן.
                  </li>
                  <li>
                    לאחר קבלת הודעת הביטול יושב למזמין הסכום ששולם על ידו בגין
                    המוצר בניכוי דמי ביטול בשיעור של 5% מסכום העסקה או $30, לפי
                    הנמוך. במקרה של ביטול עסקה עקב פגם או אי התאמה, לא יחויב
                    המזמין בדמי ביטול כלשהם. הוראות חוק הגנת הצרכן נוספות בנוגע
                    לביטול הרכישה מצד המזמין, לרבות בנוגע לחובה להחזיר המוצר,
                    יחולו גם-כן.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b> ביטול רכישה מצד החברה</b>
            </div>
            <ol type="1">
              <li>
                החברה תהא רשאית, על פי שיקול דעתה הבלעדי, בשל כל סיבה, ובכל עת,
                לבטל או להפסיק עסקה ו/או מכירה ו/או לבטל חבילה, כולה או חלקה,
                ו/או את פעילות האתר, כולו או חלקו.
              </li>
              <li>
                הודעה על ביטול או הפסקה מעין זה תימסר למשתמש או למזמין, והחברה
                תימנע מחיוב כרטיס האשראי של המזמין או תשיב לו כל סכום ששולם בגין
                המוצרים, ככל ששולם.
              </li>
              <li>
                למעט בגין השבת סכום העסקה כאמור, לא תהיה למשתמש או למזמין כל
                טענה, תביעה ו/או דרישה כלפי החברה ו/או הספק בגין ביטול העסקה
                כאמור בסעיף זה.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>זכויות יוצרים</b>
            </div>
            <ol type="1">
              <li>
                כל זכויות הקניין הרוחני, לרבות פטנטים, זכויות היוצרים, הדגמים,
                המדגמים והסודות המסחריים, הנם רכושם של החברה בלבד או של צדדים
                שלישיים אחרים שהרשו להנהלת האתר להשתמש בהם.
              </li>
              <li>
                זכויות אלה חלות בין השאר על הנתונים באתר, לרבות רשימת המוצרים,
                תאור ועיצוב המוצרים וכל פרט אחר הקשור להפעלתו.
              </li>
              <li>
                זכויות אלה חלות גם על שם האתר ושם המתחם (www.wiply.co.il ) של
                האתר, סימני המסחר (בין אם נרשמו ובין אם לא) הם כולם רכושה של
                החברה. אין לעשות בהם שימוש בלא קבלת הסכמתה בכתב ומראש.
              </li>
              <li>
                אין להעתיק, לשכפל, להפיץ, למכור, לשווק, להשכיר ולתרגם מידע כלשהו
                מהאתר, לרבות סימני מסחר, תמונות וטקסטים, עיצוב המוצרים תמונות
                המוצרים וכיו”ב בלא קבלת אישור מראש ובכתב של החברה.
              </li>
              <li>
                אין לקשר לתכנים מהאתר, שאינם דף הבית של האתר (קישור עמוק) ואין
                להציג או לפרסם תכנים כאמור בכל דרך שהיא, אלא אם הקישור העמוק
                יהיה לדף האינטרנט באתר במלוא וכפי שהוא, כך שניתן יהיה לצפות
                ולהשתמש בו באופן הזהה לחלוטין לשימוש ולצפייה בו באתר וזאת תוך
                קבלת ההסכמה מהנהלת האתר.
              </li>
              <li>
                הנהלת האתר רשאית להורות על ביטול של קישור עמוק גם לאחר מתן
                הסכמתה וזאת לפי שיקול דעתה הבלעדי ובמקרב זה לא תהיה לך כל טענה
                ו/או דרישה ו/או תביעה כלפי הנהלת האתר.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>אחריות</b>
            </div>
            <ol type="1">
              <li>
                החברה (ו/או מי מטעמה) אינה נושאת במישרין ו/או בעקיפין באחריות
                כלשהי לנזקים הנובעים ו/או הקשורים בכל דרך שהיא, על תיקונם,
                הרכבתם ו/או החלפתם של המוצרים.
              </li>
              <li>
                החברה (ו/או מי מטעמה) אינה נושאת במישרין או בעקיפין באחריות
                כלשהי לנזקים הנובעים כתוצאה משימוש ו/או הסתמכות על מידע המתפרסם
                באתרים חיצוניים, שאליהם ניתן להגיע באמצעות איזה מהשירותים באתר.
                יובהר כי החברה עושה ותעשה כמיטב יכולתה לשתף פעולה עם ספקים
                אמינים ובעלי מוניטין בלבד.
              </li>
              <li>
                החברה (ו/או מי מטעמה) אינה נושאת במישרין ו/או בעקיפין באחריות
                כלשהי לנזקים הנובעים ו/או הקשורים בכל דרך שהיא בשימוש ו/או
                בביצועי האתר.
              </li>
              <li>
                בכל מקרה החברה לא תישא באחריות לפעילות כלשהי של כל גורם אחר
                שאינו בשליטתה המלאה.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>סודיות ופרטיות</b>
            </div>
            <ol type="1">
              <li>
                כל פרטיו האישים של המזמין (שם, דואר אלקטרוני וכדומה), יישמרו
                במאגרי המידע של החברה.
              </li>
              <li>
                החברה לא תעביר פרטיו האישיים של המזמין לאף גורם אחר זולת לספקים,
                במידת הצורך וזאת רק לשם השלמת עסקה.
              </li>
              <li>
                החברה לא תעשה כל שימוש בפרטים של אמצעי התשלום של המזמין אלא
                לביצוע תשלום בגין עסקה אותה ביקש המזמין לערוך, ופרטים אלו לא
                יועברו לאף גורם אחר זולת לצורך זה. למען הסר ספק, פרטי אמצעי
                התשלום אינם נשמרים במאגרי המידע של החברה.
              </li>
              <li>
                למרות האמור לעיל, החברה תהא רשאית להעביר פרטיו האישיים של משתמש
                לצד שלישי במקרים בהם המזמין ביצע מעשה או מחדל הפוגעים ו/או
                העלולים לפגוע בחברה ו/או בצדדים שלישיים כלשהם, המזמין עשה שימוש
                בשירותי החברה לביצוע מעשה בלתי חוקי, אם התקבל בידי החברה צו
                שיפוטי המורה לה למסור את פרטי המזמין לצד שלישי וכן בכל מחלוקת או
                הליכים משפטיים.
              </li>
              <li>
                החברה תוכל להשתמש בפרטיו האישיים של המזמין, ללא זיהוי המזמין
                הספציפי, לצורך ניתוח מידע סטטיסטי והצגתו ו/או מסירתו לגורמים
                אחרים.
              </li>
              <li>
                מאחר שמדובר בביצוע פעולות בסביבה מקוונת אין באפשרותה של החברה
                להבטיח חסינות מוחלטת מפני חדירות למחשביה או חשיפת המידע האגור
                בידי מבצעי פעולות בלתי חוקיות. אם יעלה בידי צד שלישי לחדור למידע
                אשר שמור בידי החברה ו/או להשתמש בו לרעה, לא תהיה למשתמש כל טענה,
                תביעה או דרישה כלפי החברה.
              </li>
              <li>
                החברה תהא רשאית לעשות שימוש ב – “עוגיות” (“cookies”) על מנת לספק
                למשתמש שירות מהיר ויעיל ולחסוך מהמזמין הצורך להזין את פרטיו
                האישיים בכל כניסה לאתר.
              </li>
              <li>
                בקיום מקרים שאינם בשליטתה של החברה ו/או הנובעים מכח עליון, החברה
                לא תהא אחראית לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם למזמין
                ו/או למי מטעם המזמין עם מידע זה יאבד או אם יעשה בו שימוש לא
                מורשה.
              </li>
            </ol>
          </li>
          <li>
            <div>
              <b>דין ושיפוט</b>
            </div>
            <ol type="1">
              <li>
                הדין החל על תקנון זה ו/או על כל פעולה ו/או על סכסוך הנובע ממנו,
                הוא הדין הישראלי בלבד.
              </li>
              <li>
                בכל מקרה של מחלוקת, תהא לבתי המשפט (השלום או המחוזי) תל אביב-יפו
                הסמכות הבלעדית לדון בה.
              </li>
              <li>
                הדין החל על השימוש באתר, על ההזמנה ועל תקנון זה, לרבות פרשנותו
                ואכיפתו של התקנון הוא הדין הישראלי בלבד.
              </li>
            </ol>
          </li>
        </ol>
        <b>התקנון עודכן לאחרונה בתאריך 19.12.22</b>
      </div>
    </div>
  );
}

export default Terms;
