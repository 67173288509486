import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import { HashLink as HLink } from 'react-router-hash-link';

class Heroarea extends Component {
  render() {
    return (
      <section className="hero-area circle-wrap first-section">
         <div className="circle x1"></div>
         <div className="circle x2"></div>
         <div className="circle x3"></div>
         <div className="circle x4"></div>
         <div className="circle x5"></div>
         <div className="circle x6"></div>
         <div className="circle x7"></div>
         <div className="circle x8"></div>
         <div className="circle x9"></div>
         <div className="circle x10"></div>
         <div className="first">
            <div className="row full-height ">
               <div className="col-md-6  md-p-10px-b mid-c">
               <img  className=" first-section-content "  src={process.env.PUBLIC_URL + '/assets/img/logob.png'} style={{width: '100px '}} alt="wiply"/>
                  <h2 className="m-25px-b first-section-content " >{homedata.heroarea.title}</h2>
                  <p className="m-25px-b first-section-content" style={{color:'black'}}>{homedata.heroarea.content}</p>
                  <div className="hero-btn-wrapper first-section-content">
                     <a href={homedata.heroarea.btn1url} className="btn btn-default animated-btn btn-hero">{homedata.heroarea.btn1label}</a>
                     <HLink className="btn btn-default btn-default-outline animated-btn btn-hero" smooth to={`/#contact`}>צור קשר</HLink>
                  </div>
               </div>
               <div className="col-md-6 ">
                  {/* <iframe className='hero-game' src='https://wiply-website-ball.wiplify.com/' style={{border: 'none', width: '100%', height: '700px'}} /> */}
                  <img className="hero-mock" src={process.env.PUBLIC_URL+homedata.heroarea.imgurl1} alt="Hero mockup" style={{width:"100%"}}/>
               </div>
            </div>
         </div>
      </section>
    );
  }
}

export default Heroarea;
