import React, { Component } from "react";
import homedata from "../../data/homedata.json";
import { HashLink as HLink } from "react-router-hash-link";

class Feature extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL;
    let imgattr = "image";
    return (
      <section id="feature">
        <div className="circle x1"></div>
        <div className="circle x2"></div>
        <div className="circle x3"></div>
        <div className="circle x4"></div>
        <div className="circle x5"></div>
        <div className="circle x6"></div>
        <div className="circle x7"></div>
        <div className="circle x8"></div>
        <div className="circle x9"></div>
        <div className="circle x10"></div>
        <div className="container">
          <div className="bordered-box"></div>
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="section-title m-50px-b">
                <h2>{homedata.features.sectiontitle}</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12  md-p-50px-b">
              <div className="row">
                {homedata.features.featurescontent.map((feature, i) => (
                  <div key={i} className="col-sm-6">
                    <div className="single-feature">
                      <img
                        src={publicUrl + feature.icon}
                        alt={imgattr}
                        style={{ width: "45px" }}
                        className="m-10px-b"
                      />
                      <h4>{feature.title}</h4>
                      <p>{feature.content}</p>
                    </div>
                  </div>
                ))}
              </div>
              <HLink
                className="btn btn-default btn-default-outline animated-btn btn-hero"
                smooth
                to={`/#contact`}
              >
                צור קשר
              </HLink>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="position-relative featured-mockup">
                <img
                  className="phone-1"
                  src={publicUrl + homedata.features.imgurl1}
                  alt={imgattr}
                />
                <img
                  className="phone-2"
                  src={publicUrl + homedata.features.imgurl2}
                  alt={imgattr}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Feature;
