import React from 'react'
import homedata from "../../data/homedata.json";
import './How.css'
function How() {


  return (
    <div className='how'>
        <div className="section-title">
            <h2 className="tlt1" dir='rtl'>איך זה עובד</h2>
        </div>        
        <div className="screenshots">
            { homedata.howitworks.features.map((data)=>{
            return(
                <div className="screenshot">
                <div className="number">{data.number}</div>
                <div className="content">{data.content}</div>
                <img src={data.image} />
            </div>
            )
        }) }
        </div>
    </div>
  )
}

export default How

