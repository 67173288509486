import React, { useState } from "react";
import homedata from "../../data/homedata.json";
import "./Game.css";

function Game() {
  let publicUrl = process.env.PUBLIC_URL;

  const [gameUrl, setGameUrl] = useState("https://pizzahut.wiplify.com/");

  return (
    <div>
      <section id="game" className="p-100px-tb gray-bg">

        <div className="section-title text-center m-50px-b">
          <h2>{homedata.games.title}</h2>
        </div>
        <div className="container game">
          <iframe src={gameUrl} className="mac-iframe" />
          <div className="prelaodergame">
          <div className="loadergame">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        </div>
          <img className="macbook" src={publicUrl + homedata.games.macbook} />
        </div>
        <div className="games-buttons">
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://pizzahut.wiplify.com/");
            }}
          >
            <img
              className="imgcard"
              src={publicUrl + homedata.games.basketball}
            />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://pizza-hut.wiplify.com");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.bricks} />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://dwc-hockey.wiplify.com");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.hockey} />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://golf-kids-2ae3b5.wiplify.com/");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.memory} />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://subway.wiplify.com/");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.puzzle} />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://dr-fischer.wiplify.com/");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.quiz} />
          </div>
          <div
            className="games-btn"
            onClick={() => {
              setGameUrl("https://wheel-86dbcb.wiplify.com/");
            }}
          >
            <img className="imgcard" src={publicUrl + homedata.games.wheel} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Game;
